import './YajaFooter.scss';
import Accordion from 'react-bootstrap/Accordion';
import logoSrc from '../../images/logo.png';
import { useFetchFrequentCategories } from '../../hooks/useFetchFrequentCategories';

export const YajaFooter = () => {
  const alwaysOpen = true;
  const activeKey = undefined;
  const currentYear = new Date().getFullYear();
  const { frequentCategories } = useFetchFrequentCategories();

  const makeFirstLetterCap = (label: string) => {
    return label[0].toUpperCase() + label.substring(1);
  };

  return (
    <>
      <Accordion
        className='borderless-accordion'
        alwaysOpen={alwaysOpen}
        activeKey={activeKey}
      >
        <div id='yaja-footer'>
          <div className='footer-content'>
            <div className='footer-column image'>
              <a href="/">
                <img
                  className='logo'
                  src={logoSrc}
                />
              </a>
              <div className='contact-container'>
                <a className='custom-link footer-column-link'>
                  10 boulevard Haussmann
                </a>
                <a className='custom-link footer-column-link'>
                  75009 Paris France
                </a>
                <a className='custom-link footer-column-link' href="tel:+33608709929">06 08 70 99 29</a>
                <button
                  className='contact-button'
                  onClick={() => {
                    window.location.replace(`/contactus`);
                  }}
                >
                  Contact
                </button>
              </div>
            </div>

            <Accordion.Item className='footer-column' eventKey='0'>
              <Accordion.Header>
                <a className='custom-link footer-column-title'>Nos offres</a>
              </Accordion.Header>
              <Accordion.Body aria-expanded={true}>
                <div>
                  {frequentCategories.map((category) => (
                    <a
                      key={category.slug}
                      href={`/offers?category=${category.slug}`}
                      className='custom-link footer-column-link'
                    >
                      {makeFirstLetterCap(category.label)}
                    </a>
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1' className='footer-column'>
              <Accordion.Header>
                <a className='custom-link footer-column-title'>
                  Process de recrutement
                </a>
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <a
                    href='/#notre-metier'
                    className='custom-link footer-column-link'
                  >
                    Notre métier
                  </a>
                  <a
                    href='/#notre-mindset'
                    className='custom-link footer-column-link'
                  >
                    Notre mindset
                  </a>
                  <a
                    href='/#comment-ca-marche'
                    className='custom-link footer-column-link'
                  >
                    Comment ça marche
                  </a>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey='2' className='footer-column'>
              <Accordion.Header>
                <a className='custom-link footer-column-title'>A propos</a>
              </Accordion.Header>
              <Accordion.Body>
                <div>
                  <a
                    href='/whoweare'
                    className='custom-link footer-column-link'
                  >
                    Qui sommes-nous
                  </a>
                  <a
                    href='/mentions-legales'
                    className='custom-link footer-column-link'
                  >
                    Mentions légales
                  </a>
                  <a
                    href='/charte-vie-privee'
                    className='custom-link footer-column-link'
                  >
                    Politique de confidentialité
                  </a>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </div>
        </div>
      </Accordion>
      <div className='desktop-footer-content'>
        <div className='footer-section'>
          <a href="/">
            <img
              className='logo'
              src={logoSrc}
            />
          </a>
          <div className='contact-container'>
            <a className='custom-link footer-column-link'>
              10 boulevard Haussmann
            </a>
            <a className='custom-link footer-column-link'>
              75009 Paris France
            </a>
            <a className='custom-link footer-column-link' href="tel:+33608709929">06 08 70 99 29</a>
            <button
              className='contact-button'
              onClick={() => {
                window.location.replace(`/contactus`);
              }}
            >
              Contact
            </button>
          </div>
        </div>
        <div className='footer-section'>
          <h4 className='footer-column-title'>Nos offres</h4>
          <div className='section-body'>
            {frequentCategories.map((category) => (
              <a
                key={category.slug}
                href={`/offers?category=${category.slug}`}
                className='custom-link footer-column-link'
              >
                {makeFirstLetterCap(category.label)}
              </a>
            ))}
            <a
                key='all'
                href='/offers'
                className='custom-link footer-column-link'
              >
                Toutes les offres
              </a>
          </div>
        </div>
        <div className='footer-section'>
          <h4 className='footer-column-title'>Process de recrutement</h4>
          <div className='section-body'>
            <a href='/#notre-metier' className='custom-link footer-column-link'>
              Notre métier
            </a>
            <a
              href='/#notre-mindset'
              className='custom-link footer-column-link'
            >
              Notre mindset
            </a>
            <a
              href='/#comment-ca-marche'
              className='custom-link footer-column-link'
            >
              Comment ça marche
            </a>
          </div>
        </div>
        <div className='footer-section'>
          <h4 className='footer-column-title'>A propos</h4>
          <div className='section-body'>
            <a href='/whoweare' className='custom-link footer-column-link'>
              Qui sommes-nous
            </a>
            <a
              href='/mentions-legales'
              className='custom-link footer-column-link'
            >
              Mentions légales
            </a>
            <a
              href='/charte-vie-privee'
              className='custom-link footer-column-link'
            >
              Politique de confidentialité
            </a>
            <a
              href='/cgu'
              className='custom-link footer-column-link'
            >
              Conditions d'utilisation
            </a>
          </div>
        </div>
      </div>
      <p className='all-rights'>
        Yet Another Job Agency {currentYear} © Tous droits réservés
      </p>
    </>
  );
};
